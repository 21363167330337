$(function () {
	$(window).resize(function () {
		ThingsToDoOnResize();
	});

	ThingsToDoOnResize();

	function ThingsToDoOnResize() {
		if (breakpoint().indexOf("phone") != -1 && $("h4.calendar").prevAll(".jump-to").length > 0) {
			$("h4.calendar").after($(".jump-to"));
		}
		else if (breakpoint().indexOf("phone") == -1 && $("h4.calendar").nextAll(".jump-to").length > 0) {
			$("h4.calendar").before($(".jump-to"));
		}
	}

	var calendarView = $("#calendar").length > 0;
	$("#Category").change(function () {
		var selectedCategory = $(this).val();
		if (selectedCategory == "")
			selectedCategory = urlPrefix;

		if (calendarView)
			$("#calendar").fullCalendar("refetchEvents");
		else
			window.location.href = baseUrl + selectedCategory;
	});

	$("#search-box").keydown(function (e) {
		var key = e.charCode || e.keyCode || 0;
		if (key == 13)
			$("#search-go").click();
	});

	$("#search-go").click(function () {
		if (calendarView) {
			$("#calendar").fullCalendar("refetchEvents");
			return;
		}
		var selectedCategory = $("#Category").val();
		if (selectedCategory == "")
			selectedCategory = urlPrefix;
		var searchText = $("#search-box").val();
		var goToUrl = baseUrl + selectedCategory + (searchText != "" ? "?SearchText=" + encodeURIComponent(searchText) : "");
		if (window.location.pathname + window.location.search != goToUrl)
			window.location.href = goToUrl;
	});

	if (calendarView) {
		$("#calendar").fullCalendar({
			header: {
				left: 'prev,next today',
				center: 'title',
				right: 'month,agendaWeek,agendaDay'
			},
			eventSources: [{
				url: calendarUrl,
				type: 'POST',
				data: function () {
					return {
						searchText: $("#search-box").val(),
						category: $("#Category").val()
					};
				},
				success: function (calendarData) {
					if (calendarData) {
						var count = calendarData.length;
						$("#events-calendar-filter-alert").text(`${count} events found.`);
					}
				},
				error: function () {
					alert('there was an error while fetching events!');
				}
			}]
		});

		function JumpToDate() {
			var month = $("#jump-month").val();
			var year = $("#jump-year").val();
			if (month == "")
				month = 0;
			if (year == "")
				year = moment().year();

			$("#calendar").fullCalendar('gotoDate', moment({ year: year, month: month, day: 1 }));
		}

		$("#jump-month,#jump-year").change(JumpToDate);
		$(".fc-prev-button").attr("aria-label", "Previous");
		$(".fc-next-button").attr("aria-label", "Next");
	}
});